import ImageOne from "../assets/2.jpg";
import ImageTwo from "../assets/3.jpg";
import ImageThree from "../assets/4.jpg"

export const SliderData = [
  {
    img: ImageOne,
    alt: "airport",
  },
  {
    img: ImageTwo,
    alt: "airport",
  },
  {
    img: ImageThree,
    alt: "airport",
  },
];
