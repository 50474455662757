import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

const Facilities = () => {
  const [contents, setContents] = useState([]);
  const { REACT_APP_HOST } = process.env;
  const param = useParams();
  useEffect(() => {
    const func = async () => {
      const res = await axios.get(`${REACT_APP_HOST}/facilities`);
      setContents(res.data);
    };
    func();
  }, [REACT_APP_HOST]);
  console.log("hiii3",contents);

  const { REACT_APP_IMAGE_HOST } = process.env;
  return (
    <div className="min-h-[100vh] mt-[70px]">
      {contents && contents
        .filter((itemArr,index) => itemArr.id === +param.id)
        .map((item, index) => (
          <div key={index}>
            <h1 className="py-[30px] bg-[#eeeeee] text-center mb-[40px] text-[#4581E1] text-[26px] font-bold">
              {" "}
              {item.title}
            </h1>
            <img
              src={
                REACT_APP_IMAGE_HOST +
                "/" +
                item.image.split("/").slice(3).join("/")
              }
              alt=""
              className=" w-[80%] max-w-[1400px] mx-auto object-contain max-h-[40vh]"
            />
            <div className="max-w-[1400px] w-[80%] mx-auto mt-10">
              <p>{item.content}</p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Facilities;
