import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";

import ChiefCard from "./ChiefCard";

export const Chief = () => {
  const [ChiefData, setChiefData] = useState([]);
  const { REACT_APP_HOST, REACT_APP_IMAGE_HOST } = process.env;

  useEffect(() => {
    const func = async () => {
      const res = await axios.get(`${REACT_APP_HOST}/officers/`);
      setChiefData(res.data);
    };
    func();
  }, [REACT_APP_HOST]);

  return (
    <Fragment>
      <div
        className="flex  max-w-[90%] 2xl:max-w-[1400px] flex-wrap md:flex-row items-center flex-wrap gap-8   justify-center mx-auto "
        data-aos="fade-up"
      >
        <div className=" flex flex-wrap sm-w-2/5  lg:flex-nowrap gap-4 flex-1 items-center justify-center font-bold ">
          {ChiefData.slice(0, 3).map((data) => (
            <ChiefCard
              key={data.id}
              img={
                REACT_APP_IMAGE_HOST +
                "/" +
                data.image.split("/").slice(3).join("/")
              }
              post={data.jobtitle}
              name={data.name}
              phone={data.phone}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
};
