import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";

import {
  ChevronRightIcon,
  LocationMarkerIcon,
  PhoneIncomingIcon,
  InboxIcon,
} from "@heroicons/react/solid";

export const Footer = () => {
  const [viewCount, setviewCount] = useState(0);

  useEffect(() => {
    const func = async () => {
      let response = await axios.get(
        "https://api.countapi.xyz/update/dhangadhicaan/team-7/?amount=1"
      );
      console.log("viewCount", response.data.value);

      setviewCount(response.data.value);
    };
    func();
  }, []);

  return (
    <Fragment>
      <div
        className=" text-center md:text-left flex flex-wrap flex-1 w-[100%]  text-sm  justify-center  bg-[#284E8B] text-[#F9F6EE] mt-[50px]  py-10 "
        data-aos="fade-up"
      >
        <div className="sm:w-full md:w-3/5 md:pl-10 flex flex-wrap justify-between font-light ">
          <ul className="w-full mt-[20px] sm:w-1/3 sm:mt-[0] text-[#F9F6EE]">
            <a href="/">
              <li className="font-bold text-xl">Dhangadhi Airport</li>
            </a>

            <li className="mt-[10px]">
              <LocationMarkerIcon className="h-4 text-blue-500 mr-2 inline" />
              Dhangadhi,Nepal
            </li>

            <li className="mt-[10px]">
              <InboxIcon className="h-4 text-blue-500 mr-2 inline" />
              Office Chief: 091575119
            </li>

            <li className="mt-[10px]">
              <InboxIcon className="h-4 text-blue-500 mr-2 inline" />
              Admin: 091590021{" "}
            </li>

            <li className="mt-[10px] ">
              <InboxIcon className="h-4 text-blue-500 mr-2 inline" />
              Fax: 091575119
            </li>

            <li className="mt-[10px]">
              <PhoneIncomingIcon className="h-4 text-blue-500 mr-2 inline" />
              +977-061-465725
            </li>
          </ul>
          <ul className="w-full font-light text-sm mt-[20px] sm:w-1/3 sm:mt-[0]">
            <a href="#" target="_blank">
              <li className="font-bold text-xl">Airport Guide</li>
            </a>
            <a href="#" target="_blank">
              <li className="mt-[10px] hover:underline">
                <ChevronRightIcon className="h-4 text-blue-500 mr-2 inline" />
                Airport Facilities
              </li>
            </a>
            <a href="#" target="_blank">
              <li className="mt-[10px] hover:underline">
                <ChevronRightIcon className="h-4 text-blue-500 mr-2 inline" />
                Flight information
              </li>
            </a>
            <a href="#" target="_blank">
              <li className="mt-[10px] hover:underline">
                <ChevronRightIcon className="h-4 text-blue-500 mr-2 inline" />
                Floor Information
              </li>
            </a>
            <a href="#" target="_blank">
              <li className="mt-[10px] hover:underline">
                <ChevronRightIcon className="h-4 text-blue-500 mr-2 inline" />
                Arrival Information
              </li>
            </a>
            <a href="#" target="_blank">
              <li className="mt-[10px] hover:underline">
                <ChevronRightIcon className="h-4 text-blue-500 mr-2 inline" />
                Departure Information
              </li>
            </a>
            <a href="#" target="_blank">
              <li className="mt-[10px] hover:underline">
                <ChevronRightIcon className="h-4 text-blue-500 mr-2 inline" />
                Passenger Service Charges
              </li>
            </a>
          </ul>
          <ul className="w-full mt-[20px] sm:w-1/3 sm:mt-[0] text-[#F9F6EE]">
            <a href="#">
              <li className="font-bold text-xl">Useful Links </li>
            </a>
            <a href="https://www.tiairport.com.np/" target="_blank">
              <li className="mt-[10px] hover:underline">
                <ChevronRightIcon className="h-4 text-blue-500 mr-2 inline" />
                TIA
              </li>
            </a>
            <a href="https://caanepal.gov.np/en/" target="_blank">
              <li className="mt-[10px] hover:underline">
                <ChevronRightIcon className="h-4 text-blue-500 mr-2 inline" />
                CAAN
              </li>
            </a>
            <a href="https://www.tourism.gov.np/" target="_blank">
              <li className="mt-[10px] hover:underline">
                <ChevronRightIcon className="h-4 text-blue-500 mr-2 inline" />
                MoCTCA
              </li>
            </a>
            <a href="https://www.icao.int/Pages/default.aspx" target="_blank">
              <li className="mt-[10px] hover:underline">
                <ChevronRightIcon className="h-4 text-blue-500 mr-2 inline" />
                ICAO
              </li>
            </a>
            <a href="https://www.iata.org/" target="_blank">
              <li className="mt-[10px] hover:underline">
                <ChevronRightIcon className="h-4 text-blue-500 mr-2 inline" />
                IATA
              </li>
            </a>
            <a href="https://coscapsouthasia.org/" target="_blank">
              <li className="mt-[10px] hover:underline">
                <ChevronRightIcon className="h-4 text-blue-500 mr-2 inline" />
                COSCAP
              </li>
            </a>
          </ul>
        </div>
        <div className="w-full mt-[20px] md:w-2/5 md:mt-[0px] flex flex-1 justify-center items-center w-[100%] px-6 ">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3497.884585058413!2d80.58098081483872!3d28.75286268537599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a193037cab8bf3%3A0x4f559b2eb841eaca!2sDhangadhi%20Airport!5e0!3m2!1sen!2snp!4v1655395484299!5m2!1sen!2snp"
            height="340"
            width="100%"
            className="b-0 w"
            loading="lazy"
          ></iframe>
        </div>
      </div>

      <p className="text-center py-5 bg-[#1F3456] text-[#D0D0D0] text-sm">
        &copy; Copyright 2022, Dhading Airport | Designed and Developed by{" "}
        <a
          href="https://linktr.ee/team7seven"
          className="hover:underline text-white"
          target="_blank"
        >
          Team7
        </a>{" "}
        | <span className="text-xs">{viewCount}</span>
      </p>
    </Fragment>
  );
};
