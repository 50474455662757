import Hero from "./Hero";
import React, { Fragment } from "react";

export const Header = () => {
  return (
    <Fragment>
      <Hero />
    </Fragment>
  );
};
