import React from "react";
import { Body } from "../components/Body";
import { Header } from "../components/Header/Header";

const Home = () => {
  return (
    <>
      <Header />
      <Body />
    </>
  );
};

export default Home;
