import React, { Fragment } from "react";
import { Chief } from "./Chief.js";
import { Services } from "./Services.js";
import Section from "./Section";
import { Facilities } from "./Facilities.js";

export const Body = () => {
  return (
    <Fragment>
      <section className="m-auto rounded-lg text-[#484848]">
        <Services />
        <Facilities />
        <Section />
        <Chief />
      </section>
    </Fragment>
  );
};
