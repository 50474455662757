import React, { Fragment } from "react";
import "./styles.css";
import { FacilitiesSlider } from "./FacilitiesSlider";

export const Facilities = () => {
  return (
    <Fragment>
      <div
        className=" w-full max-w-[100%]  2xl:max-w-[3000px] mx-auto flex flex-wrap items-center justify-center sm:top-[-80px] relative z-10"
        data-aos="fade-up"
      >
        <div className="w-full mb-[15px] sm:mb-[0px] flex justify-center sm:w-[30%]  ">
          <div className="">
            <div className="mb-[6px] ">
              <p className=" text-[18px] blue-400 inline-block sm:block">
                Discover
              </p>
              <h2 className="text-[#4581E1] text-[26px] font-bold">
                Dhangadhi Airport
              </h2>
            </div>
            <p className="text-[18px]">Facilities and services area</p>
          </div>
        </div>
        <div className="w-full facilities-bg-color sm:w-[70%] relative z-20 py-[35px]">
          <FacilitiesSlider />
        </div>
      </div>
    </Fragment>
  );
};
