import axios from "axios";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowUpIcon,
} from "@heroicons/react/solid";

const NavLinks = (props) => {
  const [links, setLinks] = useState([]);
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");

  const { REACT_APP_HOST } = process.env;

  useEffect(() => {
    const func = async () => {
      const res = await axios.get(`${REACT_APP_HOST}/addon/`);
      setLinks(res.data);
    };

    func();
  }, [REACT_APP_HOST]);

  return (
    <>
      {links.map((link, index) => (
        <div key={index}>
          <div className="text-left px-3 md:cursor-pointer group hover:bg-blue-600 hover:text-white">
            <Link
              to={`/nav/${link.slug}`}
              className="  flex items-center justify-center cursor-pointer py-7 md:py-[25px] text-center"
              onClick={() => {
                heading !== link.name ? setHeading(link.title) : setHeading("");
                setSubHeading("");
                props?.setOpen(!props.open)
              }}
            >
              {link.title}
            </Link>
          </div>
        </div>
      ))}
    </>
  );
};

export default NavLinks;
