import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Gallery = () => {
  const { REACT_APP_HOST, REACT_APP_IMAGE_HOST } = process.env;
  const [images, setImages] = useState([]);

  const getImages = async () => {
    const res = await axios.get(`${REACT_APP_HOST}/gallery/`);
    setImages(res.data);
  };

  useEffect(() => {
    getImages();
  }, []);

  if (!images) {
    return <h1>Loading</h1>;
  }

  return (
    <div className="min-h-[100vh] mt-[70px]">
      <div className=" w-full bg-[#EEEEEE] p-10 flex justify-center">
        <h2 className="text-blue-500 text-[40px] font-bold">Gallery</h2>
      </div>
      <div className="cursor-pointer grid w-[90%] grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-8  max-w-[1440px] mx-auto mt-10">
        {images &&
          images.map((item) => (
            <div className="hover:scale-[1.05] transition 0.2s ease">
              <a
                rel="noreferrer"
                target="_blank"
                href={
                  REACT_APP_IMAGE_HOST +
                  "/" +
                  item.image.split("/").slice(3).join("/")
                }
              >
                <LazyLoadImage
                  effect="blur"
                  src={
                    REACT_APP_IMAGE_HOST +
                    "/" +
                    item.image.split("/").slice(3).join("/")
                  }
                  className="max-h-[300px]"
                  alt={item.alt}
                  key={item.id}
                />
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Gallery;
