import axios from "axios";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

import BlogCard from "./BlogCard";
import NoticesCard from "./NoticesCard";

function Section() {
  const { REACT_APP_HOST } = process.env;
  const [BlogData, setBlogData] = useState([]);
  const [NoticeData, setNoticeData] = useState([]);

  useEffect(() => {
    const func = async () => {
      const res = await axios.get(`${REACT_APP_HOST}/blogs/`);
      setBlogData(res.data);
    };

    const func2 = async () => {
      const res = await axios.get(`${REACT_APP_HOST}/notice/`);
      setNoticeData(res.data);
    };

    func();
    func2();
  }, []);

  return (
    <div
      className="max-w-[90%] 2xl:max-w-[1400px] overflow-hidden  mb-10 gap-14  flex justify-between md:mx-auto flex-col md:flex-row px-10 py-10 md:py-10 "
      data-aos="fade-up"
    >
      <div className="w-full, md:w-1/2 ">
        <div className="mb-4">
          <h2 className="text-[22px] font-semibold ">
            News From Dhangadi Airport
          </h2>
        </div>

        {BlogData.length < 1 && <p>No Blogs</p>}

        {BlogData.slice(0, 3).map((data, index) => (
          <BlogCard
            index={index}
            key={data.id}
            img={data.image}
            title={data.title}
            content={data.content}
            date={data.date}
          />
        ))}

        {BlogData.length > 3 && (
          <Link to="/blogs" className="text-faded text-md hover:underline ml-4">
            See All News
          </Link>
        )}
      </div>

      <div className="w-full  md:w-1/2 ">
        <div className="mb-4">
          <h2 className="text-[22px] font-semibold ">
            Upcoming Events and Notices
          </h2>
        </div>
        {BlogData.length < 1 && <p>No Notices</p>}

        {NoticeData.slice(0, 3).map((data, index) => (
          <NoticesCard
            index={index}
            key={data.id}
            img={data.image}
            title={data.topic}
            content={data.description}
            date={data.date}
          />
        ))}
        {NoticeData.length > 3 && (
          <Link
            to="/notices"
            className="text-faded text-md hover:underline ml-4"
          >
            See All Notices
          </Link>
        )}
      </div>
    </div>
  );
}

export default Section;
