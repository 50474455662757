import axios from "axios";
import Slider from "react-slick";
import { useState } from "react";
import React, { Fragment, useEffect } from "react";
import { ArrowRightIcon } from "@heroicons/react/solid";

import "./styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Services = () => {
  const [notice, setNotice] = useState(false);
  const [BlogData, setBlogData] = useState([]);
  const { REACT_APP_HOST, REACT_APP_IMAGE_HOST } = process.env;
  useEffect(() => {
    const func = async () => {
      const res = await axios.get(`${REACT_APP_HOST}/notice/`);
      setBlogData(res.data.slice(-10));
    };

    func();
  }, [REACT_APP_HOST]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
  };
  return (
    <Fragment>
      <div
        className="w-full max-w-[1300px] mx-auto mb-[40px] sm:pl-[40px] sm:pr-[40px] sm:mb-[0px] sm:relative sm:z-10 sm:h-[30vh]  bg-[url('./assets/map-img.png')] bg-no-repeat bg-cover"
        data-aos="fade-up"
      >
        <div className="flex flex-row w-[90%] mx-auto bg-[#EEEEEE] shadow-lg sm:absolute sm:w-[92%] sm:left-[50%] sm:ml-[-46%] sm:mb-30 sm:z-20 sm:-top-[65px] ">
          <div className="w-[200px] max-w-[200px] text-xl flex justify-center items-center text-center m-[7px] h-[95px] sm:m-[14px] text-white bg-[#064aac]">
            {" "}
            Important <br />
            Notice
          </div>

          {notice ? (
            <div className="w-full flex flex-row justify-between">
              <div className="w-full pt-[20px]">
                {Array.isArray(BlogData) && BlogData.length ? (
                  BlogData.map((data) => (
                    <div key={data.id} className="mb-[10px]">
                      <p className="font-bold">{data.date.split("T")[0]}</p>
                      <div className=" pt-[10px]">
                        <ArrowRightIcon className=" !h-[18px] mr-2 text-black-500 inline " />
                        <a
                          href={
                            REACT_APP_IMAGE_HOST +
                            "/" +
                            data.image.split("/").slice(3).join("/")
                          }
                          className="hover:underline z-10 cursor-pointer"
                        >
                          {data.topic.slice(0, 250)}
                        </a>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <p
                      key={1}
                      className="py-[10px] pl-[10px] text-base float-left"
                    >
                      No Latest Notices
                    </p>
                  </>
                )}
              </div>
              <div className="">
                <button
                  className="  color:blue text-2xl cursor:pointer pr-[10px]"
                  onClick={() => setNotice((s) => !s)}
                >
                  {" "}
                  x{" "}
                </button>
              </div>
            </div>
          ) : (
            <div className="w-[100%] flex flex-row justify-between">
              <div className="w-[90%] pt-[20px]">
                {Array.isArray(BlogData) && BlogData.length ? (
                  <Slider {...settings}>
                    {BlogData.map((data) => (
                      <div key={data.id} className="">
                        <p className="font-bold ">{data.date.split("T")[0]}</p>
                        <div className=" pt-[10px]">
                          <ArrowRightIcon className=" !h-[18px] mr-2 text-black-500 inline " />
                          <a
                            href={
                              REACT_APP_IMAGE_HOST +
                              "/" +
                              data.image.split("/").slice(3).join("/")
                            }
                            className="hover:underline z-10 cursor-pointer"
                          >
                            {data.topic.slice(0, 250)}
                          </a>
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <p key={1} className="py-[10px] pl-[10px] text-base ">
                    No Latest Notices
                  </p>
                )}
              </div>
              <div className="">
                <button
                  className=" color:blue cursor:pointer text-3xl pr-[10px]"
                  onClick={() => setNotice((s) => !s)}
                >
                  {" "}
                  +{" "}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
