import React from "react";

export default function ChiefCard({ img, post, name, phone, id }) {
  return (
    <div className="p-0 py-8 min-w-[200px]  md:mx-[40px] flex flex-wrap items-center justify-center  bg-white rounded-md shadow-sm gap-1 border-[1px] flex-1 ">
      <img
        src={img}
        alt=""
        className="h-[180px] w-[180px] object-cover"
      />
      <div className="pl-[40px]">
      <p className="text-[22px] text-[#4581E1] font-bold">{name}</p>
      <p className=" text-md font-normal">{post}</p>
      <p className="text-[#696969] text-md font-normal">Phone : {phone}</p>
      </div>
    </div>
  );
}
