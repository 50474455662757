import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const Contact = () => {
  const { REACT_APP_HOST } = process.env;
  const [contact, setContact] = useState([]);

  const getContact = async () => {
    const res = await axios.get(`${REACT_APP_HOST}/contactus/`);
    setContact(res.data);
  };

  useEffect(() => {
    getContact();
  }, []);
  console.log("contact",contact);

  if (!contact) {
    return <h1>Loading</h1>;
  }

  return (
    <div className="min-h-[100vh] mt-[70px]">
      <div className=" w-full bg-[#EEEEEE] p-10 flex justify-center">
        <h2 className="text-blue-500 text-[40px] font-bold">Contact</h2>
      </div>
      <div className="justify-center max-w-[1440px] mx-auto mt-10">
        <table className="w-full sm:w-[400px] !p-[10px] md:w-[600px] mx-auto text-left shadow-lg border-[1px] border-separate border-spacing-2 border border-slate-400 cellpadding-[20px]">
            <tr className="font-bold text-2xl bg-blue-500 text-white">
            <th className="!p-[10px]">Department/Division</th>
            <th className="!p-[10px]">Contact no</th>
            </tr>

        {contact &&
          contact.map((item) => (
            <tr className="!p-[10px] odd:bg-white even:bg-slate-100 text-xl text-black">
           <td className="!p-[10px] text-xl">{item.contact}</td>
            <td className="!p-[10px] text-xl">{item.number}</td>
            </tr>
          ))}

          </table>
      </div>
    </div>
  );
};

export default Contact;
