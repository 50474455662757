import axios from "axios";
import { Link } from "react-router-dom";
import BlogCard from "../components/BlogCard";
import React, { useEffect, useState } from "react";

export const Blogs = () => {
  const { REACT_APP_HOST } = process.env;
  const [BlogData, setBlogData] = useState([]);

  useEffect(() => {
    const func = async () => {
      const res = await axios.get(`${REACT_APP_HOST}/blogs/`);
      setBlogData(res.data);
    };

    func();
  }, []);
  return (
    <div className="mt-[100px] min-h-[100vh]">
      <div className="w-[90%] max-w-[1400px] mx-auto ">
        <Link to="/"> Go Back</Link>
        <h1 className="text-[30px] font-bold mb-6">
          All News From Dhangadi Airport
        </h1>
        {BlogData.map((data, index) => (
          <BlogCard
            index={index}
            key={data.id}
            img={data.image}
            title={data.title}
            content={data.content}
            date={data.date}
          />
        ))}
      </div>
    </div>
  );
};
