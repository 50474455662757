import axios from "axios";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

import NoticesCard from "../components/NoticesCard";

export const Notices = () => {
  const { REACT_APP_HOST } = process.env;

  const [BlogData, setBlogData] = useState([]);

  useEffect(() => {
    const func = async () => {
      const res = await axios.get(`${REACT_APP_HOST}/notice/`);
      setBlogData(res.data);
    };

    func();
  }, [REACT_APP_HOST]);
  return (
    <div className="mt-[100px] min-h-[100vh]">
      <div className="w-[90%] max-w-[1400px] mx-auto ">
        <Link to="/"> Go Back</Link>
        <h1 className="text-[30px] font-bold mb-6">
          All Upcoming Events and Notices
        </h1>
        {BlogData.map((data, index) => (
          <NoticesCard
            index={index}
            key={data.id}
            img={data.image}
            title={data.topic}
            content={data.description}
            date={data.date}
          />
        ))}
      </div>
    </div>
  );
};
