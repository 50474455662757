import React, { useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

import Button from "../UI/Button";
import NavLinks from "./NavLinks";
import logo from "../../assets/logo.png";
import { MenuIcon, XIcon } from "@heroicons/react/solid";

const Navbar = () => {
  const { REACT_APP_IMAGE_HOST } = process.env;
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(false);
  const { REACT_APP_HOST } = process.env;
  const [images, setImages] = useState([]);

  const getImages = async () => {
    const res = await axios.get(`${REACT_APP_HOST}/gallery/`);
    setImages(res.data);
  };

  useEffect(() => {
    getImages();
  }, [REACT_APP_HOST]);

  if (!images) {
    return <h1>Loading</h1>;
  }
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  window.addEventListener("scroll", changeColor);
  return (
    <nav
      className={`  text-white   w-full  flex justify-center fixed top-0 left-0 z-50 ${
        color && "bg-blue-500"
      } ${location.pathname !== "/" && "bg-blue-500"}  `}
    >
      <div className=" w-full max-w-[1400px]  h-full flex justify-between px-[40px] py-[20px] md:py-0 items-center">
        <div className="z-50 md:w-auto w-full flex  justify-between items-center">
          <a href="/" className="flex">
            <img src={logo} alt="" className="h-8 md:h-10 cursor-pointer " />

            <div className="text-left ml-[10px] sm:ml-[50px] md:ml-4">
              <div>Dhangadhi Civil Aviation office</div>
              <div>Dhangadhi Airport</div>
            </div>
          </a>
          <div onClick={() => setOpen(!open)} className="md:hidden">
            {open && <XIcon className="h-8  cursor-pointer"></XIcon>}
            {!open && <MenuIcon className="h-8  cursor-pointer text-white" />}
          </div>
        </div>
        <ul className="hidden md:flex uppercase items-center gap-8  text-sm ">
          <li className="py-6 px-3  hover:bg-blue-600">
            <Link to="/">Home</Link>
          </li>
          <li className="py-6 px-3 group hover:bg-blue-600 hover:text-white hover:cursor-pointer ">
            <Link to="/gallery">Gallery</Link>
            <div>
              <div className="absolute hidden max-w-[1000px]  justify-around items-center p-10  bg-white top-[70px] w-[73%] right-[6%]  group-hover:md:flex hover:md:flex">
                <div className=" p-3.5 text-lg text-black ">Gallery</div>
                <div className="grid grid-cols-3 gap-4">
                  {images &&
                    images
                      .slice(0, 3)
                      .map((item) => (
                        <img
                          src={REACT_APP_IMAGE_HOST + "/" + item.image.split("/").slice(3).join("/")}
                          alt={item.alt}
                          key={item.id}
                          className="h-[80px]"
                        />
                      ))}
                </div>
              </div>
            </div>
          </li>
          <li className="py-6 px-3  hover:bg-blue-600">
            <Link to="/contactus">Contact</Link>
          </li>
          <NavLinks open={open} setOpen={setOpen}  />
        </ul>
        <ul
          className={` duration-500 ${
            open ? "left-0" : "left-[-100%]"
          } text-center  md:hidden bg-blue-900 absolute top-0 w-full h-[100vh] bottom-0 left-0 py-24 pl-4`}
        >
          <li className="py-6 md:py-0 lg:px-0">
            <Link to="/" onClick={() => setOpen(!open)}>
              Home
            </Link>
          </li>
          <li className="py-6 md:py-0 lg:px-0">
            <Link to="/gallery" onClick={() => setOpen(!open)}>
              Gallery
            </Link>
          </li>
          <li className="py-6 px-3  hover:bg-blue-600">
            <Link to="/contactus" onClick={() => setOpen(!open)}>Contact</Link>
          </li>
          <NavLinks open={open} setOpen={setOpen} />
          {/* <div className="py-5 md:py-0">
            <Button>Mail Us</Button>
          </div> */}
        </ul>
      </div>
    </nav>
  );
};
export default Navbar;
