import { Fragment, useEffect } from "react";

import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Header } from "./components/Header/Header";
import { Body } from "./components/Body";
import { Footer } from "./components/Footer";
import Navbar from "./components/Header/Navbar";
import Home from "./pages/Home";
import { Notices } from "./pages/Notices";
import ScrollToTop from "./components/utils/ScrollToTop";
import Aos from "aos";
import "aos/dist/aos.css";
import NavPage from "./pages/NavPage";
import { Blogs } from "./pages/Blogs";
import Gallery from "./pages/Gallery";
import Facilities from "./pages/Facilities";
import Contact from "./pages/Contact";

function App() {
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/notices" element={<Notices />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/nav/:id" element={<NavPage />} />
        <Route path="/facilities/:id" element={<Facilities />} />
        <Route path="/contactus" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
