import React from "react";
import { ArrowRightIcon } from "@heroicons/react/solid";

function BlogCard({ index, id, img, title, content, date }) {
  const { REACT_APP_IMAGE_HOST } = process.env;
  return (
    <div
      className={`flex mb-5 ${index % 2 !== 0 ? "bg-white" : "bg-[#F7F7F7]"} `}
    >
      <div className=" px-6 py-4 space-y-2 hover:cursor-pointer">
        <div className="flex items-center gap-4">
          <p className="text-sm font-bold ml-6">2022/6/22</p>
          {index === 0 && (
            <span className="bg-red-500 px-2 py-1 text-white text-sm">New</span>
          )}
          <span className="bg-[#666666] px-2 py-1 text-white text-sm">
            {title}
          </span>
        </div>

        <div className="flex text-faded font-normal text-md">
          <div>
            <ArrowRightIcon className="h-3  mt-1 text-blue-500 mr-2 " />
          </div>

          <a href={
              REACT_APP_IMAGE_HOST + "/" + img.split("/").slice(3).join("/")
            } className="hover:underline">
            {title}
          </a>
        </div>
      </div>
    </div>
  );
}
export default BlogCard;
