import axios from "axios";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useState } from "react";

import "swiper/css";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Autoplay, Navigation, FreeMode, Scrollbar } from "swiper";
const { REACT_APP_HOST } = process.env;
const { REACT_APP_IMAGE_HOST } = process.env;

export const FacilitiesSlider = () => {
  const [FacilitiesData, setFacilitiesData] = useState([]);

  useEffect(() => {
    const func = async () => {
      const res = await axios.get(`${REACT_APP_HOST}/facilities/`);
      setFacilitiesData(res.data.slice(-10));
    };
    func();
  }, []);
  return (
    <>
      <div className="w-full overflow-auto ">
        <Swiper
          slidesPerView="auto"
          spaceBetween={50}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          freeMode={true}
          navigation={false}
          scrollbar={{
            el: ".swiper-scrollbar",
            draggable: true,
            dragSize: 150,
          }}
          breakpoints={{
            640: {
              slidesPerView: "auto",
              spaceBetween: 15,
            },
            768: {
              slidesPerView: "auto",
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: "auto",
              spaceBetween: 15,
            },
          }}
          modules={[Autoplay, Navigation, FreeMode, Scrollbar]}
          className="mySwiper"
        >
          {FacilitiesData.map((item) => (
            <SwiperSlide>
              <div className=" !h-[397px] inline-block cursor-pointer hover:scale-105 ease-in-out duration-300 bg-white shadow-lg" key={item.id}>
              <Link to={`/facilities/${item.id}`} >
                <img
                  className="!w-[260px] !h-[172px] inline-block"
                  src={
                    REACT_APP_IMAGE_HOST +
                    "/" +
                    item.image.split("/").slice(3).join("/")
                  }
                  alt="kkk"
                />
                <div className="!w-[260px] !h-[204px] px-6 my-4">
                  <div className="inline-block font-neuton font-bold text-xl mb-2 text-[#4581E1] justify-content">
                    {item.title}
                  </div>
                  <p className="text-base ">{item.content.slice(0, 90)}</p>
                </div>
                </Link>
              </div>
            </SwiperSlide>
          ))}
          <div className="swiper-scrollbar mt-[20px] relative z-50 !w-[400px] !h-[5px] m-auto rounded">
            <div className="swiper-scrollbar-drag !bg-[#007aff] !h-[5px] rounded"></div>
          </div>
        </Swiper>
      </div>
    </>
  );
};
