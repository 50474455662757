import React, { useRef, useState } from "react";
import { SliderData } from "../../db/SliderData";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import { useEffect } from "react";

const Hero = () => {
  const [current, setCurrent] = useState(1);
  const length = SliderData.length;
  const timeout = useRef(null);

  useEffect(() => {
    const nextSlide = () => {
      setCurrent((current) => (current === length - 1 ? 0 : current + 1));
    };
    timeout.current = setTimeout(nextSlide, 5000);

    return function () {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [length, current]);

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(SliderData) || SliderData.length <= 0) {
    return null;
  }

  return (
    <div className="h-[80vh] relative overflow-hidden bg-slate-400 z-0">
      <div className="w-full h-full flex justify-center items-center overflow-hidden relative">
        {SliderData.map((slide, index) => {
          return (
            <div className="w-full h-full" key={index}>
              {index === current && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center before:absolute before:z-[2] before:w-full before:h-full before:opacity-[0.2] before:left-0 before:bottom-0 before:bg-black">
                  <img
                    src={slide.img}
                    className=" absolute top-0 left-0 w-full h-full object-cover"
                    alt=""
                  />
                  <div className=" text-center lg:text-left absolute z-20 text-white text-[50px] uppercase font-bold">
                    WELCOME TO DHANGADHI AIRPORT
                  </div>
                </div>
              )}
            </div>
          );
        })}
        <div className="flex absolute bottom-2 right-2 gap-2 z-10 cursor-pointer">
          <div className="bg-black p-2 rounded-full" onClick={prevSlide}>
            <ArrowLeftIcon className="h-[30px] w-[30px] text-white" />
          </div>
          <div className="bg-black p-2 rounded-full" onClick={nextSlide}>
            <ArrowRightIcon className="h-[30px] w-[30px] text-white" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
